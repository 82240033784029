.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
   color: white;
}

.categorias {
   border-radius: 20px 0px 0px 20px;
}

/* .barra-busqueda {
   max-width: 31.25rem;
} */

.barra-busqueda-input .rbt-input-main {
   text-indent: 30px;
   border-radius: 0px 20px 20px 0px;
   /* width: 31.25rem; */
   width: 100%;
}

.barra-busqueda-input .rbt-input-hint {
   text-indent: 30px;
   width: 31.25rem;
}

.icono-busqueda {
   left: 10px;
   top: 8px;
}

.user-profile-dropdown {
   border-right: 1px solid white;
}

@media (max-width: 768px) {
   .brand {
      display: none;
   }
   .categorias {
      border-radius: 20px 20px 20px 20px;
   }
   .barra-busqueda-input .rbt-input-main {
      text-indent: 30px;
      border-radius: 20px 20px 20px 20px;
      width: 100%;
   }
}

@media (min-width: 992px) {
   .barra-busqueda-container {
      width: 800px;
   }
}
