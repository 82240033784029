.product-name {
   color: var(--dark-blue-color);
   font-weight: 600;
}

.product-code,
.product-description-header {
   font-size: 1.125rem;
}

.product-price {
   color: var(--main-color);
   font-size: 1.75rem;
}

.product-name,
.product-price,
.product-description-header {
   font-weight: 600;
}

#quantity {
   max-width: 80px;
}
