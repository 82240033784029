.sidebar {
   min-width: 250px;
   max-width: 250px;
   height: 100vh;
   background-color: var(--main-color);
   color: #efefef;
   /* Transitions */
   -moz-transition: all 0.3s ease;
   -webkit-transition: all 0.3s ease;
   -o-transition: all 0.3s ease;
   transition: all 0.3s ease;
}

.sidebar-header {
   background-color: var(--main-color);
   padding: 10px 15px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 60px;
   border-bottom: 1px solid #fff;
}

.sidebar-header h3 {
   margin-bottom: 0px;
   font-size: 1.4em;
   font-weight: bold;
}

.sidebar-header .btn-toggle:hover {
   color: var(--main-color);
   cursor: pointer;
}

.sidebar-links {
   padding: 0rem;
}

.sidebar-links li {
   border: none;
   background-color: transparent;
}

.sidebar-links a.active,
.sidebar-links a:hover {
   background-color: #fff;
   color: var(--main-color);
}

.sidebar-links a {
   color: #efefef;
   text-decoration: none;
   /* Transitions */
   -moz-transition: all 0.2s ease;
   -webkit-transition: all 0.2s ease;
   -o-transition: all 0.2s ease;
   transition: all 0.2s ease;
   outline: none;
}

.list-group .list-group-item {
   background-color: transparent;
   border: none;
}

/** ******************************************** */
/** Collapsed */
/** ******************************************** */

.sidebar.collapsed {
   max-width: 80px;
   min-width: 80px;
}

.sidebar.collapsed .sidebar-header {
   justify-content: center;
}

.sidebar.collapsed header h3 {
   display: none;
}

.sidebar.collapsed .sidebar-links {
   padding: 0rem;
}

.sidebar.collapsed .sidebar-links a li.list-group-item {
   padding: 0rem;
}

.sidebar.collapsed .sidebar-links a {
   padding: 10px;
   font-size: 0.8em;
   text-align: center;
   /* overflow: hidden;
   word-wrap: break-word; */
}

.sidebar.collapsed .sidebar-links svg {
   width: 100%;
   font-size: 1.5em;
   margin-left: 0;
   margin-right: 0;
}

.sidebar.collapsed .sidebar-links a span {
   display: none;
}

/** ******************************************** */
/** Movil */
/** ******************************************** */

@media (max-width: 768px) {
   .sidebar.collapsed {
      max-width: 20px;
      min-width: 20px;
   }

   .sidebar.collapsed .sidebar-links {
      display: none;
   }

   .sidebar.collapsed .sidebar-header {
      display: none;
   }
}

@media (max-width: 1199.98px) {
   .sidebar {
      font-size: 0.7rem;
      min-width: 200px;
      /* max-width: 200px; */
   }
}
