.carrito {
   min-height: 30rem;
}

.carrito-productos-card,
.resumen-card {
   box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
}

.carrito-total {
   font-size: 1.25rem;
   font-weight: bold;
}
