.auth {
   height: 100vh;
   width: 100%;
   background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../assets/images/mision-background.jpg');
   background-size: cover;
   background-position: center;
   display: grid;
}

.auth-card {
   /* height: 31.967rem; */
   width: 31.25rem;
   margin: auto;
   box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
}

.auth-card .card-header {
   font-size: 1.3125rem;
   font-weight: bold;
}
