.perfil {
   min-height: 30rem;
}

.confirmation-modal-text {
   font-size: 1rem !important;
   font-weight: 600 !important;
}

.brand-icon {
   width: 40px;
}
