:root {
   --main-color: #0044a4;
   --secondary-color: #29aae1;
   --gray-color: #333333;
   --dark-blue-color: #1e1e33;
}

/****************************************/
/**             Generales               */
/****************************************/
a:hover {
   text-decoration: none;
}

.cursor-pointer {
   cursor: pointer;
}

.cursor-not-allowed {
   cursor: not-allowed;
}

.font-weight-medium {
   font-weight: 600;
}

.breadcrumb {
   background-color: white !important;
}

.product-card-price {
   font-weight: 600;
}

/****************************************/
/**            Backgrounds              */
/****************************************/
.bg-primary {
   background-color: var(--main-color) !important;
}
.bg-white {
   background-color: white !important;
}
.bg-footer {
   background-color: var(--dark-blue-color);
}

/****************************************/
/**            	Buttons            	 */
/****************************************/
.btn {
   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.btn-primary {
   background-color: var(--main-color) !important;
   border-color: var(--main-color) !important;
}

.btn-info {
   background-color: var(--secondary-color) !important;
   border-color: var(--secondary-color) !important;
}

.btn-light {
   background-color: white !important;
   border-color: white !important;
}

.btn-outline-secondary {
   border: 2px solid #6c757d;
}
/****************************************/
/**            	 Text             	 */
/****************************************/
.text-primary {
   color: var(--main-color) !important;
}

.text-gray {
   color: var(--gray-color) !important;
}

/****************************************/
/**            	 Forms             	 */
/****************************************/
.form-group .form-label {
   font-weight: 600;
}

.form-control {
   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.invalid-field {
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #dc3545;
}

/****************************************/
/**            	 Cards             	 */
/****************************************/
.card-shadow {
   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
