.categories-card {
   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.category-link {
   font-weight: 600;
}

.category-link:hover {
   text-decoration: underline;
   cursor: pointer;
}

.results-counter {
   font-weight: 600;
}

.productos {
   min-height: 30rem;
}
