.header-text {
   border-bottom: 1px solid var(--main-color);
   font-weight: bold;
}

.product-card {
   font-size: 0.875rem;
   font-weight: 600;
   line-height: 19px;
   text-align: center;
   border: none;
}

.product-name {
   text-transform: capitalize;
   text-overflow: ellipsis;
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.product-card-price {
   font-weight: 600;
}

.mision {
   background-image: url('../../assets/images/mision-background.jpg');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
}

.mision .overlay {
   background-color: rgba(30, 30, 51, 0.85);
}

.mision-container {
   border-right: 2px solid;
}

.mision-title {
   font-size: 1.875rem;
   font-weight: bold;
   line-height: 44px;
}

#whatsapp {
   color: #fff;
   background-color: #00e676;
   border-radius: 50px;
   position: fixed;
   right: 0px;
   top: 90%;
   z-index: 100;
   height: 50px;
   width: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 5px;
   margin-right: 5px;
   text-decoration: none;
   box-shadow: 1px 1px rgba(0, 0, 0, 0.4);
   -moz-box-shadow: 1px 1px rgba(0, 0, 0, 0.4);
   -webkit-box-shadow: 1px 1px rgba(0, 0, 0, 0.4);
   -o-box-shadow: 1px 1px rgba(0, 0, 0, 0.4);
}
