.proceso-pago {
   min-height: 40rem;
}
.tabs-proceso-pago {
   font-size: 0.875rem;
   font-weight: 600;
   justify-content: center;
}

.tabs-proceso-pago.nav-tabs {
   border-bottom: none;
}

.tabs-proceso-pago .nav-item {
   color: var(--main-color);
   text-align: center;
   width: 188px;
}

.tabs-proceso-pago .nav-link:focus {
   border-color: transparent;
}

.tabs-proceso-pago .nav-item.active {
   color: var(--main-color);
   font-weight: bold;
   border-top-color: transparent;
   border-left-color: transparent;
   border-right-color: transparent;
   border-bottom: 3px solid var(--main-color);
}

.tabs-proceso-pago .nav-item:hover {
   font-weight: bold;
   border-top-color: transparent;
   border-left-color: transparent;
   border-right-color: transparent;
   border-bottom: 3px solid var(--main-color);
}

.btn-back {
   color: var(--main-color);
}

.btn-stepper {
   width: 200px;
}

.important-text {
   /* font-size: 1.125rem; */
   font-weight: 600;
}

.active-address,
.active-card {
   border: 3px solid var(--main-color);
}

.agradecimiento {
   min-height: 40rem;
}

.agradecimiento-card {
   max-width: 42.16625rem;
   min-height: 28.564375rem;
   background-image: url('../../../assets/images/agradecimiento.png');
   background-size: contain;
   background-repeat: no-repeat;
   background-position: bottom;
}

.agradecimiento-text-main {
   width: 316.17px;
}

.agradecimiento .btn-thanks {
   left: 230px;
   bottom: 40px;
}
