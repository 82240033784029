.ubicacion:hover {
   text-decoration: underline;
}

.footer ul {
   list-style-type: none;
   padding-left: 0;
   line-height: 1.7;
   font-weight: 600;
}

.footer .left-list li,
.footer .right-list li {
   margin-bottom: 1rem;
}

.footer .center-list li:first-child {
   border-bottom: 1px solid white;
}
